:root{
  --mainBlue:#2a2a72;
  --lightBlue:#009ffd;
  --mainWhite:#f3f3f3;
  --mainDark:#232528;
  --mainYellow:#ffa400;
}
body{
  font-family: 'Oswald', sans-serif !important;
  background:var(--mainWhite) !important;
  color:var(--mainDark)!important;
}
.text_title{
  font-family: 'Permanent Marker', cursive;
  letter-spacing:.3rem;
  text-transform: uppercase;
}
.text_blue{
  color:var(--mainBlue)
}
.text_bright{
  color:var(--lightBlue)
}
.btn_black{
  background-color:transparent;
  text-transform: capitalize;
  font-size: .8rem !important;
  color:var(--mainDark);
  border-radius: 0 !important;
  border:.1rem var(--mainDark) solid !important;
}

.btn_black:hover{
  background-color:var(--mainDark) !important;
  color:var(--mainWhite) !important;
}
.cart_icon{
  cursor:pointer;
  font-size: 1.3rem;
  color:var(--mainYellow);
  
}






