@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Permanent Marker', cursive; */
  /* font-family: 'Oswald', sans-serif; */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}